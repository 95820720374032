import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';

export default function UserBubble({textStyle, text}) {
    return (
        <div className="user-bubble">
            <Box sx={{ width: 50, }} />
            <Paper style={textStyle} 
                sx={{borderTopLeftRadius: 10,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10, }}
                elevation={3}> {text} 
            </Paper>
        </div>
    )
}