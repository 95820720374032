
import Box from '@mui/material/Box';
// import useSound from 'use-sound'
// import outputSound from '/home/john/VScode/doc_app_react/doctors-app/src/output.mp3' 


import Paper from '@mui/material/Paper';

export default function BotBubble({textStyle, text}) {
    console.log("->>>", text)
    // const [playSound] = useSound(outputSound);
    // playSound();
    return (
        <div className="bot-bubble">
            <Paper style={textStyle}
                sx={{borderTopLeftRadius: 0,
                     borderTopRightRadius: 10,
                     borderBottomLeftRadius: 10,
                     borderBottomRightRadius: 10, }}
                elevation={3}> {text}
            </Paper>
            <Box sx={{ width: 50, }} />
        </div>
    )
}