import React, { useEffect } from 'react';

import './App.css';
import BotBubble from './bot_bubble.js'
import UserBubble from './user_bubble.js';
import 'react-chat-elements/dist/main.css'
import { Fab } from '@material-ui/core';
import { RiRobot2Line } from "react-icons/ri";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { StyleSheet, SafeAreaView, ScrollView, StatusBar } from 'react-native-web'

import { useState} from "react";
import axios from 'axios';

import { useLongPress } from "@uidotdev/usehooks";
import { IoMdMic } from "react-icons/io";

// const host = "http://0.0.0.0:8080/assistantv0"
const host = "https://ada.dozeelab.com/assistantv0"
var apiKey = "AIzaSyB3Qii5Tezl2Hw6NPXge8ufoU0SdGiCC4A"

var state = {
  // Get audio file in a variable
  audio: new Audio()
};

function App() {
  const [userName, setUserName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [transcript, setTranscript] = useState('');
  const [loading, setLoading] = useState(false)
  const [chatHistory, setChatHistory] = useState([]);

  const [mediaRecorder, setMediaRecorder] = useState(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [pressStart, setPressStart] = React.useState(false);
  const [audio, setAudio] = React.useState("");

  const scrollViewRef = React.useRef();


  function completeTranscription(results){
    console.log("heRe",results)
    var transcript = ""
    for (var i = 0; i < results.length; i++) {
      transcript += results[i].alternatives[0].transcript
    }
    return transcript
  }

  const attrs = useLongPress(
    () => {
      setIsOpen(true);
    },
    {
      onStart: (event) => {console.log("Press started"); 
                           setPressStart(true); // For color green
                           state.audio.pause()
                           startRecording();
                          },
      onFinish: (event) => {console.log("Press Finished"); 
                           setPressStart(false);
                           stopRecording();
                           },
      onCancel: (event) => {console.log("Press cancelled");
                            setPressStart(false); // For color red
                           },
      threshold: 500,
    }
  );

  // Function to convert audio blob to base64 encoded string
const audioBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const base64Audio = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      resolve(base64Audio);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        console.log("USE EFFECT", inputValue, "<-");
      }
    };
  }, [mediaRecorder, audio]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // setAudio(stream.)
      console.log("++++", stream.getAudioTracks())
      stream.addEventListener("audio", (data) => {setAudio(data); console.log("CHENGES")})
      const recorder = new MediaRecorder(stream);
      recorder.start();
      console.log('Recording started');
      console.log(recorder)

      // Event listener to handle data availability
      recorder.addEventListener('dataavailable', async (event) => {
        console.log('Data available event triggered');
        const audioBlob = event.data;
        console.log(event.data.arrayBuffer())
        console.log("audioBlob: ", audioBlob)

        const base64Audio = await audioBlobToBase64(audioBlob);
        // console.log('Base64 audio:', base64Audio.length);
        // console.log(typeof(base64Audio))
        // console.log(base64Audio)

        try {
          console.log("Initiating transcription...")
          const startTime = performance.now();
          
          // User's Voice-To-Text
          const response = await axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
            {
              config: {
                encoding: 'WEBM_OPUS',
                sampleRateHertz: 48000,
                languageCode: 'en-UK',
              },
              audio: {
                content: base64Audio,
              },
            }
          );
          const endTime = performance.now();
          const elapsedTime = endTime - startTime;
          
          
          console.log('API response:', response);
          console.log('Time taken (ms):', elapsedTime);
          try{
            console.log("--> ", response.data.results[0].alternatives[0].transcript)
            // response.data.results.forEach(completeTranscription)
            var transcription = completeTranscription(response.data.results)
            // console.log("--> ")
          }catch{
            return
          }

          if (response.data.results && response.data.results.length > 0) {
            console.log("YES")

                  // Populating the USER SIDE conversation
                  setLoading(true)
                  setChatHistory(oldChat => [...oldChat, {
                    role:"user",
                    parts: transcription
                  }])
                  setInputValue("")
                  
                  // console.log(event) setInput
                  console.log(transcription)
                  const options = {
                    // mode: 'no-cors',
                    method : 'POST',
                    body : JSON.stringify({"user_id": userName, "query": transcription}),
                    headers : {
                      'Content-Type': 'application/json',
                      'Accept': 'json'
                    }
                  }
                  
                  console.log(options)
                  console.log("------0------")

                  // Users Text-To_Text post request to Our Gemini Server
                  const response_ = await (await fetch(`${host}/chat`, options)).text()
                  
                  // Populating the BOT SIDE conversation
                  setChatHistory(oldChat => [...oldChat, {
                    role:"model",
                    parts: response_
                  }])
                  setInputValue("")
                  console.log("chat history response" ,response_) 


                  // Bot's Text-To-Voice
                  console.log("response_: ", response_)
                  const response_tts = await axios.post(
                    `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
                    {
                      input: {
                        text: JSON.parse(response_).response,
                        // text: "Kya aap mujhe sun sakte ho?"
                      },
                      voice: {
                        "languageCode":"en-IN",
                        "name":"en-IN-Neural2-B",
                       
                      },
                      audioConfig: {
                      "audioEncoding": "MP3"
                      }
                    }
                  );

                  console.log("TTS RESPONSE:",response_tts.data.audioContent)
                  
                  // Playing the audio response on the browser
                  var snd = new Audio("data:audio/wav;base64," + response_tts.data.audioContent);
                  state.audio = snd
                  // snd.play();
                  state.audio.play()
                  setLoading(false)
                  console.log("DONE")




          } else {
            console.log('No transcription results in the API response:', response.data);
          }
        } catch (error) {
          console.error('Error with Google Speech-to-Text API:', error.response.data);
        }
      });

      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Error getting user media:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log('Recording stopped');
    }
  };

  function delay(time) {
    return new Promise(resolve => setTimeout(stopRecording, time));
  }


  const handleRefresh = async () => {
    const options = {
      // mode: 'no-cors',
      method : 'POST',
      body : JSON.stringify({"user_id": userName}),
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'json'
      }
    }

    const response = await (await fetch(`${host}/refreshbot`, options)).text()

    setChatHistory(oldChat => [...oldChat, {
      role:"model",
      parts: response
    }])
    setInputValue("")
    console.log("chat history response" ,response) 

    console.log("------1------")
    // Bot's Text-To-Voice
    console.log("response_: ", response)
    const response_tts = await axios.post(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
      {
        input: {
          text: JSON.parse(response).response,
          // text: "Kya aap mujhe sun sakte ho?"
        },
        voice: {
          "languageCode":"en-IN",
          "name":"en-IN-Neural2-B",
         
        },
        audioConfig: {
        "audioEncoding": "MP3"
        }
      }
    );

    console.log("TTS RESPONSE:",response_tts.data.audioContent)
    
    // Playing the audio response on the browser
    var snd = new Audio("data:audio/wav;base64," + response_tts.data.audioContent);
    state.audio = snd
    // snd.play();
    state.audio.play()
    setLoading(false)
    console.log("DONE")
  }

  ////////////////////////////////////////////////////////////////////////////////////
  const handleText = async () =>  { 
      if (inputValue.length === 0){
        return
      }

      setLoading(true)
      // $("#input-container-text-box").val('');
      setChatHistory(oldChat => [...oldChat, {
        role:"user",
        parts: inputValue
      }])
      setInputValue("")
      
      // console.log(event) 
      console.log(inputValue)
      const options = {
        // mode: 'no-cors',
        method : 'POST',
        body : JSON.stringify({"user_id": userName, "query": inputValue}),
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'json'
        }
      }
      
      console.log(options)
      console.log("------0------")
      const response = await (await fetch(`${host}/chat`, options)).text()
        
      setChatHistory(oldChat => [...oldChat, {
        role:"model",
        parts: response
      }])
      setInputValue("")
      console.log("chat history response" ,response) 

      console.log("------1------")
      // Bot's Text-To-Voice
      console.log("response_: ", response)
      const response_tts = await axios.post(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
        {
          input: {
            text: JSON.parse(response).response,
            // text: "Kya aap mujhe sun sakte ho?"
          },
          voice: {
            "languageCode":"en-IN",
            "name":"en-IN-Neural2-B",
           
          },
          audioConfig: {
          "audioEncoding": "MP3"
          }
        }
      );

      console.log("TTS RESPONSE:",response_tts.data.audioContent)
      
      // Playing the audio response on the browser
      var snd = new Audio("data:audio/wav;base64," + response_tts.data.audioContent);
      state.audio = snd
      // snd.play();
      state.audio.play()
      setLoading(false)
      console.log("DONE")
      
    }


  const styles = StyleSheet.create({
  appMain: {
    // display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },

  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    padding: 10,
    height: "80vh",
    width: '100%',
    // margin: 5,
    // backgroundColor: 'green'
  },

  parentContainer:{
    width: '100%'
  },

  scrollContainer:{
    padding: 10,
    // height: '80%',
    // width: '100%',
  },
  card:{display: 'flex', justifyContent:'flex-end'},

  scrollView: {
    alignItems: 'end',
    // backgroundColor: 'pink',
    // marginHorizontal: "18%",
    padding: "10%",
  },
  scrollViewRev: {
    alignItems: 'end',
    // backgroundColor: 'pink',
    // marginHorizontal: "18%",
    padding: "10%",
  },
  botText: {
    flexShrink: 1,
    fontSize: 17,
    color: 'black',
    padding: 4,
    marginTop: 5,
    backgroundColor: 'white',
    
    whiteSpace: 'pre-line', 
    wordBreak: 'break-word', 
    overflowWrap: 'break-word',
    textAlign: 'left'
  },
  userText: {
    flexShrink: 1,
    fontSize: 17,
    color: 'white',
    padding: 4,
    marginTop: 5,
    backgroundColor: '#0066FF ',

    whiteSpace: 'pre-line', 
    wordBreak: 'break-word', 
    overflowWrap: 'break-word',
    textAlign: 'left'
  },
  box1: {
    flex: 1,
    flexDirection: "row",
    width: "80%",
    alignItems: 'end',
    backgroundColor: 'blue',
    // padding: 50,
  },
  buttonDefault:{
    borderWidth:1,
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 100,
    borderColor:'rgba(0,0,0,0.1)',
    alignItems:'center',
    justifyContent:'center',
    width:55,
    height:55,
  },
  buttonPressed:{
    borderWidth:1,
    backgroundColor: '#00FF08',
    borderRadius: 100,
    borderColor:'rgba(0,0,0,0.1)',
    alignItems:'center',
    justifyContent:'center',
    width:55,
    height:55,
  },
  buttonBotRefresh:{
    borderWidth:1,
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 100,
    borderColor:'rgba(0,0,0,0.1)',
    alignItems:'center',
    justifyContent:'center',
    width:35,
    height:35,
  },
  userBox:{
    // flex: 0,
    // width:50,
    // height:30,
    flexDirection: "row",
    padding: 5,
    // backgroundColor: 'black',
    alignItems:'center',
    justifyContent: 'center',

  }


});


// setPlay(play +1)
return (
    <div className="App">
      {/* {playSound()} */}
      
      <div className="App-main" style={styles.appMain}>
        
        <div className="App-name">
          Dozee Assistant
        </div>
        <div className="User-main" style={styles.userBox}>
          <div>
            <TextareaAutosize
              className="user-container-text-box"
              maxRows={1} 
              placeholder="Enter First Name"
              onChange={(e) => {
                setUserName(e.target.value);
                // console.log("userbox")
              }}
            />
          </div>
        </div>

        
        <div className="conversation-box" style={styles.parentContainer}>      
          <SafeAreaView className="chat" style={styles.container}>
            <ScrollView contentContainerStyle={styles.scrollContainer} 
              ref={scrollViewRef}
              onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
              >
              {
                chatHistory.map((chatItem, _index) => 
                chatItem.role === "user"
                ? <UserBubble textStyle={styles.userText} text={chatItem.parts} />
                : <BotBubble textStyle={styles.botText} text={JSON.parse(chatItem.parts).response} />
                ) 
              }
              {/* {console.log("XXXXXXXXXXXX")}         */}
            </ScrollView>
            
          </SafeAreaView>      
        </div>
        
        <div className="input-container">
          <Fab className="input-container-text-submit-button">
            <RiRobot2Line size={30} onClick={handleRefresh}/>
          </Fab>
          <TextareaAutosize 
            className = "input-container-text-box"
            maxRows={5} 
            placeholder="How are my patients doing today?"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            value = {inputValue}
          />
          <Fab className="input-container-text-submit-button" disabled = {loading}>
            <KeyboardDoubleArrowUpIcon onClick={() => {handleText() }}       
            />
          </Fab>
          {/* <Fab className="input-container-text-submit-button">
            <MicIcon onClick={startRecording}/>
          </Fab> */}
          {/* <Fab className="input-container-text-submit-button" style={styles.button}>
            <PiSpeakerSimpleSlashFill onClick={snd.stop()}/>
          </Fab> */}
          <button {...attrs} style={pressStart ? styles.buttonPressed : styles.buttonDefault} 
                             disabled = {loading}
                             onContextMenu={(e)=> e.preventDefault()}>
            <IoMdMic size={30}/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
